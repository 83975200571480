export default new class RequestHandler {
    constructor() {
        const axios = require('axios');

        this.axiosInstance = axios.create({
            responseType: 'json',
            headers: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization2': 'D2X1hu6cbpedlQTwT040i6zB&PgiT1' }
        });
        this.axiosInstance.interceptors.response.use(response => response, (error) => {
            if ((error.response.status === 403 || error.response.status === 401) && typeof window === 'object' && window.location.pathname.indexOf('/user/reset-password') !== 0 && window.location.pathname !== '/profile' && window.location.pathname !== '/standalone/tableau' && window.location.pathname !== '/login' && window.location.pathname !== '/register' && window.location.pathname !== '/teaser' && !(['/standalone/tableau', '/terms-of-use', '/privacy-policy', '/dcma-notice', '/about', '/about-us', '/help', '/subscription-agreement', '/contact-us', '/teaser'].includes(window.location.pathname))) {
                this.loadUserInfo().then(userInfo => {
                    if (userInfo.login === 'anonymous') {
                        window.location.href = '/login';
                    } else if (window.location.pathname.indexOf('/user/reset-password') !== 0 && window.pathname !== '/standalone/tableau' && window.location.pathname !== '/teaser' && window.location.pathname !== '/profile' && window.location.pathname !== '/verify' && window.location.pathname !== '/legal' && window.location.pathname !== '/about' && window.location.pathname !== '/contct-us' && !(['/terms-of-use', '/privacy-policy', '/dcma-notice', '/about', '/about-us', '/help', '/subscription-agreement', '/contact-us', '/teaser'].includes(window.location.pathname))) {
                        window.location.href = '/teaser?denied=Y';
                    }
                }).catch(error => window.location.href = '/login');
            }

            return Promise.reject(error);
        });

        this.apiPrefix = process.env.VUE_ENV === 'server'
            ? process.env.VUE_APP_API_PREFIX_SERVER
            : process.env.VUE_APP_API_PREFIX_CLIENT;
        this.apiGraphql = this.apiPrefix.replace('/api', '/graphql');
    }

    loadPage(pageUrl) {
        return this.doGetRequest(this.apiPrefix + '/page' + pageUrl);
    }

    loadPageAlerts(pageUrl) {
        return this.doGetRequest(this.apiPrefix + '/alerts' + pageUrl);
    }

    loadPageGuidance(pageUrl) {
        return this.doGetRequest(this.apiPrefix + '/guidance' + pageUrl);
    }

    loadSiteConfig() {
        return this.doGetRequest(this.apiPrefix + '/site_config');
    }

    loadAgencies() {
        return this.doGetRequest(this.apiPrefix + '/agencies');
    }

    loadFullAgencies() {
        return this.doGetRequest(this.apiPrefix + '/agencies/full');
    }

    loadVendor(vendorLocationId) {
        return this.doGetRequest(this.apiPrefix + '/vendors/vendor/' + vendorLocationId);
    }

    loadContractDetails(contractLocationId) {
        return this.doGetRequest(this.apiPrefix + '/contracts/contract/details/' + contractLocationId);
    }

    loadContract(contractLocationId) {
        return this.doGetRequest(this.apiPrefix + '/contracts/contract/' + contractLocationId);
    }
    loadTopVendors() {
        return this.doGetRequest(this.apiPrefix + '/vendors/vendor/top_vendors' );
    }
    loadPreviousPositionEmploymentUpdates(
        positionContentId,
        offset,
        limit
    ) {
        return this.doGetRequest(this.apiPrefix + '/contacts/previous/' + positionContentId
            + '?offset=' + offset + '&limit=' + limit
        ).then((response) => {
            return response.ContentList || {};
        });
    }

    loadFullContacts(
        agencyLocationId,
        offset, limit, sortField = 'last_name', sortOrder = 'asc',
        keyFirst = false,
        searchQuery
    ) {
        return this.doGetRequest(this.apiPrefix + '/contacts/full/' + agencyLocationId
            + '?offset=' + offset + '&limit=' + limit
            + '&sortField=' + sortField + '&sortOrder=' + sortOrder
            + (keyFirst ? '&keyFirst=true' : '')
            + (searchQuery ? '&q=' + encodeURIComponent(searchQuery) : '')
        );
    }

    loadFullContactsByProgram(programLocationId, offset, limit, sortField = 'last_name', sortOrder = 'asc', keyFirst = false, searchQuery) {
        return this.doGetRequest(this.apiPrefix + '/contacts/full_program/' + programLocationId + '?offset=' + offset + '&limit=' + limit + '&sortField=' + sortField + '&sortOrder=' + sortOrder + (keyFirst ? '&keyFirst=true' : '') + (searchQuery ? '&q=' + encodeURIComponent(searchQuery) : ''));
    }

    loadProgram(programLocationId) {
        return this.doGetRequest(this.apiPrefix + '/programs/single/' + programLocationId);
    }

    loadProgramOrganizationAllotments(programLocationId, offset, limit, sortField, isAsc) {
        return this.doGetRequest(this.apiPrefix + '/programs/organization_allotments/' + programLocationId + '/' + offset + '/' + limit + '/' + sortField + '/' + isAsc);
    }

    loadProcurements(keyword, offset, limit, sortField, isAsc, agencyIds, types, domains, showActive, statuses, startStartDate, startEndDate, endStartDate, endEndDate) {
        let domainFilter = '';
        domains && domains.forEach((item, i) => {
            domainFilter += `&domains[${i}]=` +  encodeURIComponent(item);
        });

        return this.doGetRequest(this.apiPrefix + '/agencies/full_procurements/' + offset + '/' + limit + '/' + sortField + '/' + isAsc + '?query=' + encodeURIComponent(keyword) + '&agencyIds=' + encodeURIComponent(agencyIds) + '&types=' + encodeURIComponent(types) + '&showActive=' + encodeURIComponent(showActive) + '&statuses=' + encodeURIComponent(statuses) + '&startStartDate=' + encodeURIComponent(startStartDate) + '&startEndDate=' + encodeURIComponent(startEndDate) + '&endStartDate=' + encodeURIComponent(endStartDate) + '&endEndDate=' + encodeURIComponent(endEndDate) + domainFilter);
    }

    loadProcurement(locationId) {
        return this.doGetRequest(this.apiPrefix + '/agencies/procurement/' + locationId);
    }

    loadDocuments(keyword, offset, limit, sortField, isAsc, agencyIds, yearValues, domainValues, typesValues) {
        let extraPart = '';
        if (yearValues) {
            extraPart = '&years=' + encodeURIComponent(yearValues);
        }
        if (domainValues) {
            extraPart += '&domains=' + encodeURIComponent(domainValues);
        }
        if (typesValues) {
            extraPart += '&types=' + encodeURIComponent(typesValues);
        }
        return this.doGetRequest(this.apiPrefix + '/agencies/full_documents/' + offset + '/' + limit + '/' + sortField + '/' + isAsc + '?query=' + encodeURIComponent(keyword) + '&agencyIds=' + encodeURIComponent(agencyIds) + extraPart);
    }

    loadDocument(locationId) {
        return this.doGetRequest(this.apiPrefix + '/agencies/document/' + locationId);
    }

    loadDocumentTypes() {
        return this.doGetRequest(this.apiPrefix + '/agencies/document-types');
    }

    loadProcurementsClosing(sortField, sortIsAs) {
        return this.doGetRequest(this.apiPrefix + '/agencies/procurements/closing/' + sortField + '/' + sortIsAs);
    }

    loadProcurementsRecent(sortField, sortIsAs) {
        return this.doGetRequest(this.apiPrefix + '/agencies/procurements/recent/' + sortField + '/' + sortIsAs);
    }

    loadProcurementsInfo() {
        return this.doGetRequest(this.apiPrefix + '/agencies/procurements/info');
    }
    loadContractsInfo() {
        return this.doGetRequest(this.apiPrefix + '/agencies/contracts/info')
    }

    loadDocumentsInfo() {
        return this.doGetRequest(this.apiPrefix + '/agencies/documents/info');
    }

    loadVoterInfo(contactContentId) {
        return this.doGetRequest(this.apiPrefix + '/contacts/voter_info/' + contactContentId);
    }

    loadTurnoverAll() {
        return this.doGetRequest(this.apiPrefix + '/contacts/turnover');
    }

    loadTurnOver(agencyId) {
        return this.doGetRequest(this.apiPrefix + '/contacts/turnover_agency/' + agencyId);
    }

    loadUserInfo() {
        return this.doGetRequest(this.apiPrefix + '/user/info');
    }

    loadRecentUpdates(limit, excludeTwitter, offset) {
        return this.doGetRequest(this.apiPrefix + '/latest_updates/bookmarked/' + (limit ? limit : 100) + '/' + (excludeTwitter ? '1' : '0') + '/' + (offset ? offset : 0));
    }

    loadAllRecentUpdates(limit, offset, type, subtree) {
        return this.doGetRequest(this.apiPrefix + '/latest_updates/all/' + (limit ? limit : 100) + '/' + (offset ? offset : 0) + '/' + type + '?' + (subtree ? 'subtree=' + subtree : ''));
    }

    loadPath(locationId) {
        return this.doGetRequest(this.apiPrefix + '/path/' + locationId);
    }

    loadSearch(query, page = 1, filters = null) {
        const url = this.apiPrefix + '/search/search';
        let reqParams = { 'q': query };
        if (page) {
            reqParams.page = page;
        }
        if (filters) {
            reqParams = { ...reqParams, ...filters };
        }
        return this.doGetRequest(url, reqParams);
    }

    loadRSSFeed(contentId, limit, offset) {
        return this.doGetRequest(this.apiPrefix + '/rss/feed/' + contentId + '?limit=' + limit + '&offset=' + offset);
    }

    loadKeyEvents() {
        return this.doGetRequest(this.apiPrefix + '/key_events/all');
    }

    loadBookmarkLocationIds() {
        return this.doGetRequest(this.apiPrefix + '/bookmarks/ids').then(response => response.ids || []);
    }

    createNote(params, csrfToken) {
        const formData = new FormData();
        formData.append('title', params.title);
        formData.append('text', params.text);
        params.relatedIds && params.relatedIds.forEach((item, i) => {
            formData.append(`relatedIds[${i}]`, item);
        });
        if (params.files) {
            for (var i = 0; i < params.files.length; i++) {
                formData.append(`files[${i}]`, params.files[i]);
            }
        }

        return this.axiosInstance.post(this.apiPrefix + '/notes/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-Token': csrfToken,
            }
        }).then(response => response.data || {});
    }

    editNote(params, csrfToken) {
        const formData = new FormData();
        formData.append('title', params.title);
        formData.append('text', params.text);
        params.relatedIds && params.relatedIds.forEach((item, i) => {
            formData.append(`relatedIds[${i}]`, item);
        });
        if (params.files) {
            for (var i = 0; i < params.files.length; i++) {
                formData.append(`files[${i}]`, params.files[i]);
            }
        }
        params.deletedFiles && params.deletedFiles.forEach((item, i) => {
            formData.append(`deletedFiles[${i}]`, item);
        });

        return this.axiosInstance.post(this.apiPrefix + '/notes/edit/' + params.locationId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-Token': csrfToken,
            }
        }).then(response => response.data || {});
    }

    deleteNote(locationId, csrfToken) {
        return this.axiosInstance.delete(this.apiPrefix + '/notes/delete/' + locationId, {
            headers: { 'X-CSRF-Token': csrfToken }
        }).then(response => response.data || {});
    }

    addBookmarkId(locationId, csrfToken) {
        return this.axiosInstance.post(this.apiPrefix + '/ezp/v2/bookmark/' + locationId, {}, {
            headers: {
                'X-CSRF-Token': csrfToken,
            }
        }).then((response) => {
            return response.data || {};
        });
    }

    removeBookmarkId(locationId, csrfToken) {
        return this.axiosInstance.delete(this.apiPrefix + '/ezp/v2/bookmark/' + locationId, {
            headers: {
                'X-CSRF-Token': csrfToken,
            }
        }).then((response) => {
            return response.data || {};
        });
    }

    loadBookmarkedObjects(type, limit, offset = 0) {
        const params = {};
        if (type) {
            params.type = type;
        }
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }

        return this.doGetRequest(this.apiPrefix + '/bookmarks/list', params).then((response) => {
            return response.ContentList || {};
        });
    }

    loadNotedObjects(limit, offset = 0, relatedIds = null, sort = 'date_modified desc', typeFilter = '', query = '') {
        const params = {};
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }
        if (relatedIds && relatedIds.length) {
            params.relatedIds = relatedIds;
        }
        if (sort) {
            params.sort = sort;
        }
        if (typeFilter) {
            params.typeFilter = typeFilter;
        }
        if (query) {
            params.query = query;
        }

        return this.doGetRequest(this.apiPrefix + '/notes/list', params).then((response) => {
            return response.ContentList || {};
        });
    }

    loadContracts(
        limit, offset = 0,
        vendorId = null,
        programId = null,
        agencyId = null,
        contractsIds = null,
        keyword = '',
        sortField, sortIsAsc,
        minAmount= null,
        maxAmount= null,
        beginDate=null,
        endDate=null,
        yearValues ,
        type = null,
        exactMatch = false,
        callFrom = null,
    ) {
        const params = {};
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }
        if (vendorId) {
            params.vendorId = vendorId;
        }
        if (programId) {
            params.programId = programId;
        }
        if (agencyId) {
            params.agencyId = agencyId;
        }
        if (contractsIds && contractsIds.length) {
            params.contractsIds = contractsIds.join(',');
        }
        if (keyword) {
            params.keyword = keyword;
        }

        if (sortField) {
            params.sortField = sortField;
            params.sortIsAsc = sortIsAsc ? '1' : '0';
        }
        if (minAmount) {
            params.minAmount = minAmount;
        }
        if (maxAmount) {
            params.maxAmount = maxAmount;
        }
        if (beginDate) {
            params.beginDate = beginDate;
        }
        if (endDate) {
            params.endDate = endDate;
        }
        if (callFrom) {
            params.callFrom = callFrom;
        }
        params.loadOld = 0;

        if (yearValues) {
            if (yearValues === 'all') {
                params.loadOld = 1  ;
            }
            params.years = yearValues;
        }
        if (type) {
            params.type = type;
        }
        if (exactMatch) {
            params.exactMatch = exactMatch;
        }
        return this.doGetRequest(this.apiPrefix + '/contracts/list', params).then((response) => {
            return response.ContentList || {};
        });
    }


    loadNotedObjectsCount(relatedIds = null) {
        const params = {};
        if (relatedIds && relatedIds.length) {
            params.relatedIds = relatedIds;
        }

        return this.doGetRequest(this.apiPrefix + '/notes/count', params).then((response) => {
            return response.count || 0;
        });
    }

    loadRecursivePositionsBudgets(positionId) {
        return this.doGetRequest(this.apiPrefix + '/contacts/position_budgets/' + positionId).then((response) => {
            return response.ContentList.list || {};
        });
    }

    loadRecentNewsAll(num) {
        return this.doGetRequest(this.apiPrefix + '/rss/recent_news_all/' + num).then((response) => {
            return response.ContentList.list || {};
        });
    }
    loadFeed( ) {
        return this.doGetRequest(this.apiPrefix + '/rss/feed/flgovfeed').then((response) => {
            return response || {};
        })
    }

    loadPositionBudgets(positionId, limit = 100) {
        return this.doGraphqlRequest(`{
      content {
        budgets(first: ${limit}, query: {Field: {target: "positions", contains: "${positionId}"}}) {
          pageInfo { hasNextPage }
          edges {node {
            name, appropriationAmount, disbursementAmount, _location {urlAliases { path } parentLocation {contentInfo {name, id}}}
          }}
        }
      }
    }`).then(response => {
            const items = (response.content && response.content.budgets.edges || []).map(item => item.node)
            const hasNextPage = response.content && response.content.budgets.pageInfo.hasNextPage;

            return { items, hasNextPage };
        });
    }

    loadAgencyBudgets(agencyId, limit = 100, includeProgram = false) {
        let programFields = '';
        if (includeProgram) {
            programFields += ' _location {urlAliases {path}, parentLocation {contentInfo {name, id}, urlAliases {path}}}'
        }

        return this.doGraphqlRequest(`{
      content {
        budgets(first: ${limit}, query: {SubtreeByContentId: ${agencyId}}) {
          pageInfo { hasNextPage }
          edges {node {
            name, appropriationAmount, appropriationAmountGeneralRevenue, appropriationAmountTrustFunds, disbursementAmount${programFields}
          }}
        }
      }
    }`).then(response => {
            const items = (response.content && response.content.budgets.edges || []).map(item => item.node)
            const hasNextPage = response.content && response.content.budgets.pageInfo.hasNextPage;
            items.forEach(item => {
                item.url = item._location.urlAliases[0].path;
            });
            if (includeProgram) {
                items.forEach(item => {
                    item.program = item._location.parentLocation.contentInfo;
                    if (item.program.name === 'Budget') {
                        item.program.name = item.name;
                    } else {
                        item.program.url = item._location.parentLocation.urlAliases[0]?.path;
                    }
                    delete item._location;
                })
            }

            return { items, hasNextPage };
        });
    }

    loadTableauJwt() {
        return this.doGetRequest(this.apiPrefix + '/user/tableau_jwt')
        .then((response => response.token || ''));
    }

    loadTableauJwtPublic() {
        let url = this.apiPrefix + '/user/tableau_jwt_public?s=' + encodeURIComponent('zAMB36Z#q2mMlLv7');
        return this.doGetRequest(url)
        .then((response => response.token || ''));
        // return this.axiosInstance
        //     .post(this.apiPrefix + '/user/tableau_jwt_public', { 's': 'zAMB36Z#q2mMlLv7' }, {
        //         responseType: 'json',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json',
        //         }
        //     })
        //     .then((response) => {
        //         return response.data?.token || '';
        //     });
    }

    loadBudgetsHistory(agencyId, programId) {
        return this.doGetRequest(this.apiPrefix + '/agencies/budget_history/' + agencyId, { programId })
            .then((response => response.records || []));
    }

    loadTopAgencies() {
        return this.doGetRequest(this.apiPrefix + '/agencies/top')
            .then((response => response.ContentList || []));
    }

    loadAgenciesTree() {
        return this.doGetRequest(this.apiPrefix + '/agencies/tree')
            .then((response => { return response || {} }));
    }

    loadHistoricalBudgets(agencyContentId, domain) {
        return this.doGetRequest(this.apiPrefix + '/historical_budgets', { agencyContentId, domain })
            .then((response => response.historical || []));
    }

    loadCategoriesTree(programLocationId) {
        return this.doGetRequest(this.apiPrefix + '/programs/categories_tree/' + programLocationId);
    }

    loadAgencyContactsTree(agencyId) {
        return this.doGetRequest(this.apiPrefix + '/contacts/tree/' + agencyId)
            .then((response => response && response[0] || []));
    }

    loadOrgChartTree() {
        return this.doGetRequest(this.apiPrefix + '/orgchart/tree')
            .then((response => response && response[0] || []));
    }

    loadAllDefinitions() {
        return this.doGetRequest(this.apiPrefix + '/definitions/list?offset=0&limit=999')
            .then((response => response.ContentList || []));
    }

    loadChargebeeConfig() {
        return this.doGetRequest(this.apiPrefix + '/user/chargebee_config');
    }

    loadChargebeePortalSession() {
        return this.doGetRequest(this.apiPrefix + '/user/chargebee_portal_session');
    }

    loadCompanyUsers() {
        return this.doGetRequest(this.apiPrefix + '/user/company_users');
    }

    loadChargebeeCheckout(planOption) {
        return this.doGetRequest(this.apiPrefix + '/user/chargebee_checkout', { planOption });
    }

    loadPositionByContentId(contentId) {
        return this.doGetRequest(this.apiPrefix + '/contacts/position_by_content_id/' + contentId);
    }

    loadContactTravels(contactId, limit = 4, offset = 0) {
        let offsetFilter = '';
        if (offset > 0) {
            offsetFilter = ', after: "' + btoa('arrayconnection:' + offset) + '"';
        }

        return this.doGraphqlRequest(`{
            content {
                travels(first: ${limit}${offsetFilter}, query: {
                    Field: {target: "person", contains: "${contactId}"},
                    SortByField: {type: "travel", target: "start_date", direction: "DESC"}
                }) {
                    pageInfo { hasNextPage }
                    edges {node {
                        totalCost, destination, purpose
                        startDate { format(pattern: "m/d/Y") }
                        endDate { format(pattern: "m/d/Y") }
                    }}
                }
            }
        }`).then(response => {
            const items = (response.content && response.content.travels.edges || []).map(item => item.node)
            const hasNextPage = response.content && response.content.travels.pageInfo.hasNextPage;

            return { items, hasNextPage };
        });
    }

    loadContactTravelsCount(contactId) {
        return this.doGraphqlRequest(`{
      content {
        travels(last: 1, query: {Field: {target: "person", contains: "${contactId}"}}) {
          pageInfo { endCursor }
        }
      }
    }`).then(response => {
            let count = response.content && response.content.travels.pageInfo.endCursor;
            if (typeof atob === 'function') {
                count = parseInt(atob(response.content.travels.pageInfo.endCursor).split(':')[1]) || 0;
            }

            return count || 0;
        });
    }

    sessionRefresh(sessionId, csrfToken, eZSessIdKey) {
        function setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        }

        setCookie(eZSessIdKey, sessionId, 1);
        return this.axiosInstance
            .post(this.apiPrefix + '/ezp/v2/user/sessions/' + sessionId + '/refresh', {}, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Accept': 'application/vnd.ez.api.Session+json',
                    //'Cookie': eZSessIdKey + '=' + sessionId, // doesn't work, so we use setCookie
                }
            })
            .then((response) => {
                return response.data || {};
            });
    }

    fetchResource(userReference) {
        return this.axiosInstance
            .get(userReference['_href'], {
                responseType: 'json',
                headers: {
                    'Accept': userReference['_media-type']
                }
            })
            .then((response) => {
                return response.data || {};
            });
    }

    signIn(payload) {
        const signInRequest = () => this.axiosInstance
            .post(this.apiPrefix + '/ezp/v2/user/sessions', { 'SessionInput': payload }, {
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/vnd.ez.api.SessionInput+json',
                    'Accept': 'application/vnd.ez.api.Session+json'
                }
            })
            .then((response) => {
                return response.data || {};
            });

        // as we don't know about session cookie exist or not (as it's HttpOnly cookie), logout first
        return this.logout().then(signInRequest).catch(signInRequest);
    }

    sendVerificationCode(email, csrfToken) {
        let reqData = {
            'email': email || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/send_verification_code', reqData, {
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrfToken,
                }
            })
            .then(response => response.data || {});
    }

    verify(email, code, csrfToken) {
        let reqData = {
            'email': email || '',
            'code': code || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/verify', reqData, {
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrfToken,
                }
            })
            .then(response => response.data || {});
    }

    agreeTos(payload, csrfToken) {
        let reqData = {
            'agreed_tos': payload.agreedTos || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/agree_tos', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    signUp(payload, csrfToken) {
        let reqData = {
            'ezplatform_content_forms_user_register[fieldsData][first_name][value]': payload.firstName || '',
            'ezplatform_content_forms_user_register[fieldsData][last_name][value]': payload.lastName || '',
            'ezplatform_content_forms_user_register[fieldsData][user_account][value][username]': payload.username || '',
            'ezplatform_content_forms_user_register[fieldsData][user_account][value][email]': payload.email || '',
            'ezplatform_content_forms_user_register[fieldsData][user_account][value][password][first]': payload.password || '',
            'ezplatform_content_forms_user_register[fieldsData][user_account][value][password][second]': payload.password || '',
            'ezplatform_content_forms_user_register[fieldsData][company_name][value]': payload.companyName || '',
            'ezplatform_content_forms_user_register[fieldsData][industry][value]': payload.industry || '',
            'ezplatform_content_forms_user_register[fieldsData][phone][value]': payload.phone || '',
            'ezplatform_content_forms_user_register[fieldsData][agreed_tos][value]': payload.agreedTos || '',
            'ezplatform_content_forms_user_register[register]': 1,
            'isCompany': payload.isCompany || '',
            'forCompany': payload.forCompany || '',
            'companyAudience': payload.companyAudience || '',
            'companyUrl': payload.companyUrl || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/register', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    forgotPassword(email, csrfToken) {
        let reqData = {
            'user_password_forgot[email]': email || '',
            'user_password_forgot[reset]': '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/forgot_password', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    resetNewPassword(params, csrf) {
        let reqData = {
            'user_password_reset[new_password][first]': params.newPassword || '',
            'user_password_reset[new_password][second]': params.newPassword || '',
            'hashKey': params.token || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/reset_password', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrf,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    updatePassword(payload, csrfToken) {
        let reqData = {
            'user_password_change[oldPassword]': payload.oldPassword || '',
            'user_password_change[newPassword][first]': payload.newPassword || '',
            'user_password_change[newPassword][second]': payload.confirmPassword || '',
            'user_password_change[change]': 1,
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');

        return this.axiosInstance
            .post(this.apiPrefix + '/user/change-password', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    editUser(userParams, csrfToken) {
        let reqData = {
            'userParams[first_name]': userParams.first_name || '',
            'userParams[last_name]': userParams.last_name || '',
            'userParams[email]': userParams.email || '',
            'userParams[phone]': userParams.phone || '',
            'userParams[company_name]': userParams.company_name || '',
            'userParams[industry]': userParams.industry || '',
            'userParams[image]': userParams.image || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');
        return this.axiosInstance
            .post(this.apiPrefix + '/user/edit_profile', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    editCompany(companyParams, csrfToken) {
        let reqData = {
            'name': companyParams.name || '',
            'url': companyParams.url || '',
            'audience': companyParams.audience || '',
        };
        reqData = Object.entries(reqData)
            .map((val) => `${val[0]}=${encodeURIComponent(val[1])}`)
            .join('&');
        return this.axiosInstance
            .post(this.apiPrefix + '/user/edit_company', reqData, {
                responseType: 'json',
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data || {});
    }

    logout(sessionId, csrfToken) {
        return this.axiosInstance.delete(this.apiPrefix + '/ezp/v2/user/sessions/' + sessionId, {
            responseType: 'json',
            headers: { 'X-CSRF-Token': csrfToken }
        });
    }

    decryptData(data, csrfToken) {
        let d = {
            'data': data || '',
        };
        return this.axiosInstance.post(this.apiPrefix + '/register-magic-link/decode', d, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
            }
        }).then(response => response.data || {});
    }

    doGetRequest(path, params = null) {
        const config = {};
        if (params) {
            config.params = params;
        }
        return this.axiosInstance.get(path, config).then((response) => {
            return response.data || {};
        });
    }

    doGraphqlRequest(query) {
        return this.axiosInstance.post(this.apiGraphql, {
            "query": query
        }).then((response) => {
            return response.data && response.data.data || {};
        });
    }
}
