import {idFormat} from "@/helpers/filter";
import {zoomout} from "@/helpers/charts";

export const scrollToTile = function (item) {

    let name = item.name.replace(/[^A-Z0-9]/ig, "_");
    const element = document.querySelector("[data-anchor='" + name + "']");
    
    const budget = element.parentElement.parentElement;

    // calculate correct scrollTo position
    const budgetRect = budget.getBoundingClientRect()
    const bodyRect = document.body.getBoundingClientRect();
    let navigation = document.getElementById('primary-navigation__container');
    if (!navigation) {
        navigation = document.getElementById('mobile-navigation');
    }
    navigation = navigation.getBoundingClientRect();
    let y = bodyRect.height - (bodyRect.bottom - budgetRect.bottom) - (budgetRect.height) - navigation.height;

    // scroll to position and trigger tool tip
    triggerMouseEvent(budget.firstElementChild, 'mouseover');
    var clonetip = duplicateToolTip(budget);
    goodScroll(clonetip);
}


const sleep = m => new Promise(r => setTimeout(r, m));

const getPath = function (el, item) {
    if (el.locationId === item) return [{'name' : el.name}];
    else if (el.children) {
        let children = el.children;
        for (let child of children) {
            let result = getPath(child, item);
            if (result) {
                if (el.locationId)result.unshift({'name' : el.name});
                return result;
            }
        }
    }
}

const clickToTileCallback = async function(arr) {

    if (!arr) return [];

    window.pauseToolTip = true;

    for (let i = 0; i < arr.length; i++) {
        let name = arr[i].name.replace(/[^A-Z0-9]/ig, "_");
        const element = document.querySelector("[data-anchor='" + name + "']");
        const budget = element.parentElement.parentElement;

        //  adding needed event
        arr[i].name !== arr.at(- 1).name
            ? triggerMouseEvent(budget.firstElementChild, "click")
            : triggerMouseEvent(budget.firstElementChild, 'mouseover');

        // scroll to position and trigger tool tip
        triggerMouseEvent(budget.firstElementChild, 'mouseover');
        var clonetip = duplicateToolTip(budget);
        // window.scrollTo({top: y, behavior: 'smooth'});
        goodScroll(clonetip);
        await sleep(500);
    }

    window.pauseToolTip = false;
}

export const clickToTile = async function (item, treeMap, data) {

    if (data) {
        const arr = getPath(data, item.locationId);
        treeMap.zoomOutToLevel(0, clickToTileCallback, arr);
    } else {
        treeMap.zoomOutToLevel(0, scrollToTile, item);
    }

}

export const triggerMouseEvent = function(element, event) {
    element.dispatchEvent(new MouseEvent(event, { 'view': window, 'bubbles': true, 'cancelable': true }));
}

export const duplicateToolTip = function(el) {
    removeClone();
    const toolTip = el.closest('.treemap-wrapper').querySelector('.treemap-tooltip');

    let clone = toolTip.cloneNode(true);
    clone.id = 'treemap-tooltip-clone';
    clone.onclick = function(event) {
        if (this.getAttribute("href")) {
            window.location.href = this.getAttribute("href");
        }
        let dataType = document.getElementById('view-vendor-link').getAttribute('data-type');
        if (dataType !== 'Disbursement Vendor') {
            //zoomin(d);
            triggerMouseEvent(el, 'click');
            //tooltipHide(null);
        }
        else {
            let el = document.getElementById('view-vendor-link');
                if (el.getAttribute('data-content-id') && el.getAttribute('data-location-id')) {
                window.currentVendor = {
                    data: {
                        vendorContentId: el.getAttribute('data-content-id'),
                        name: el.getAttribute('data-name'),
                        transparencyName: '',
                        factsName: '',
                        factsSecondaryName: '',
                        vendorLocationId: el.getAttribute('data-location-id'),
                    },
                };
                window.showVendor();
            }
        }
    };

    addCloseButton(clone);
    toolTip.after(clone);
    return clone;
}

export const removeClone = function () {
    const clone = document.getElementById('treemap-tooltip-clone');
    if (clone !== null)
    {
        clone.remove();
    }
}

function addCloseButton(element) {
    const brk = document.createElement('br');
    let button = document.createElement('button');
    button.style.textDecoration = "none";
    button.innerHTML = 'X';
    button.classList.add('treemap-tooltip-close');
    button.onclick = function(e) {
        e.preventDefault();
        e.stopPropagation();
        removeClone();
    };

    element.prepend(brk);
    element.prepend(button);
}

const goodScroll = function(element) {
    if (element) {
        const viewportHeight = window.innerHeight;
        const elementHeight = element.clientHeight;
        const offset = (viewportHeight - elementHeight) / 2;
      
        // Calculate the scroll position to center the element
        const scrollPosition = element.getBoundingClientRect().top + window.scrollY - offset;
      
        // Use smooth scrolling for a nicer effect
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      } else {
        console.log("Element not found");
      }
}