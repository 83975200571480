<template>
    <!-- Component: organisms/contracts-list -->

    <general-container
        :title="title"
        :titleClass="titleClass"
        :custom-class="customContainerClass"
    >
        <div class="filter-container container" >

            <div class="columns" >
                <div class="column" v-if="searchAgency" >
                    <label :for="'tbl-agency-' + uniqueId" class="label">Search by Agency</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="[{value: '', name: 'All Agencies'}].concat(agencies.map(item => ({value: item.contentId, name: item.name})).filter(x => x.name !== 'Legislative Branch' && x.name !== 'Administered Funds'))"
                            name="agencyId"
                            placeholder="Select an Agency..."
                            v-model:value="agencyId"
                        ></custom-select>
                    </div>
                </div>

                <div class="column" v-if="searchType">
                    <label :for="'tbl-type-' + uniqueId" class="label">Search by Type</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="types"
                            name="type"
                            placeholder="Select a Type..."
                            v-model:value="type"
                        ></custom-select>
                    </div>
                </div>

                <div class="column" v-if="searchDomain" >
                    <label for="tbl-domain" class="label">Search by Domain</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="domains"
                            name="domain"
                            placeholder="Select a Domain..."
                            v-model:value="domain"
                        ></custom-select>
                    </div>
                </div>

<!--                <div class="filter-input field">-->
            </div>

            <div class="columns">
                <div class="column">
                    <div :id="'applied-filters-' + uniqueId"></div>
                </div>
            </div>

            <div class="columns">
                <div class="column is-full-mobile">
                    <div class="card date-range-card amount">
                        <div class="card-content">
                            <h3><label class="label">Search by Amount</label></h3>
                            <div class="columns">
                                <div class="column">
                                    <label class="control-title" :for="'minAmount-' + uniqueId">Enter Min Amount:</label>
                                    <div class="control">
                                        <input v-model="minAmount" type="number" :id="'minAmount-' + uniqueId" class="input-amount"  placeholder="minAmount" />
                                    </div>
                                </div>
                                <div class="column">
                                    <label class="control-title" :for="'maxAmount-' + uniqueId">Enter Max Amount:</label>
                                    <div class="control">
                                        <input v-model="maxAmount" type="number" :id="'maxAmount-' + uniqueId" class="input-amount" placeholder="maxAmount"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-full-mobile">
                    <div class="card date-range-card">
                        <div class="card-content">
                            <h3>
                                <label class="label">
                                    Search by Date Range:
                                <b @click="searchCurrentYear()" class="range_filter_preset">
                                     Current Fiscal Year
                                </b> |
                                    <b @click="allYear()" class="range_filter_preset">
                                        All Years
                                    </b>
                                </label>
                            </h3>
                            <div class="date-set">
                                <div class="columns">
                                    <div class="column">
                                        <span class="control-title">Beginning Date:</span>
                                        <div class="control">
                                            <div class="control-icon">
                                                <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                            </div>
                                            <div class="control-input">
                                                <Datepicker
                                                    v-model="startDate"
                                                    :clearable="true"
                                                    :inputFormat="'MM/dd/yyyy'"
                                                    :typeable="true"
                                                    :placeholder="'mm/dd/yyyy'"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <span class="control-title">End Date:</span>
                                        <div class="control">
                                            <div class="control-icon">
                                                <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                            </div>
                                        <div class="control-input">
                                                <Datepicker
                                                    v-model="endDate"
                                                    :clearable="true"
                                                    :inputFormat="'MM/dd/yyyy'"
                                                    :typeable="true"
                                                    :placeholder="'mm/dd/yyyy'"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column">
                <label for="tbl-keyword" class="label display-label">Filter by Keyword</label>
                <div class="search-block">
                    <div class="field has-addons">
                        <div class="control">
                            <input type="search" id="tbl-keyword" v-model="keyword" class="input search-block__search-input "
                                   placeholder="Filter by keyword"
                            />
                        </div>
                        <div class="control">
                            <div class="button">
                                <span class="search-block__search-input__icon"><font-awesome-icon :icon="faSearch" aria-label="Calendar icon"></font-awesome-icon></span>
                            </div>
                        </div>
                    </div>
                    <div class="exact-match">
                        <input :id="'exact-match-contracts'"
                               class="switch is-rounded"
                               v-on:click="clickExactMatchContracts($event)"
                               type="checkbox"
                               :value="'Exact match'"
                        >
                        <label :for="'exact-match-contracts'" class="status-filter-label">Exact match</label>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="filter-info">{{ totalContractsCount || '0' }} items found</div>
                    <div class="filter-limit">
                        <label>
                            Show
                            <select name="filter-limit--value" v-model="itemsPerPage">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                            items
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="responsive-table" :class="scrollable == false">
            <table class="zebra" :key="random">
                <thead>
                    <tr>
                        <th v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" class="not-sortable" scope="col">
                            Agency
                        </th>
                        <th class="clickable" @click="sortField = 'name'; sortIsAsc = !sortIsAsc;" scope="col">
                            Name <span class="sort-status"><span :class="sortField === 'name' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'name' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable" @click="sortField = 'total_amount'; sortIsAsc = !sortIsAsc;" scope="col">
                            Total amount <span class="sort-status"><span :class="sortField === 'total_amount' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'total_amount' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable" @click="sortField = 'type'; sortIsAsc = !sortIsAsc;" scope="col">
                            Type <span class="sort-status"><span :class="sortField === 'type' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'type' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th v-if="vendorshow==true" class="clickable" @click="sortField = 'vendor'; sortIsAsc = !sortIsAsc;" scope="col">
                            Vendor <span class="sort-status"><span :class="sortField === 'vendor' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'vendor' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable has-tooltip-hover" @click="sortField = 'begin_date'; sortIsAsc = !sortIsAsc;" scope="col" v-if="definitions['Begin date']">
                            <span class="word">Begin date</span>
                            <span class="tooltip">
                                {{ definitions['Begin date'].definition }}
                            </span>
                            <span class="sort-status"><span :class="sortField === 'begin_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'begin_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable" @click="sortField = 'begin_date'; sortIsAsc = !sortIsAsc;" scope="col" v-else>
                            Begin date <span class="sort-status"><span :class="sortField === 'begin_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'begin_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable has-tooltip-hover" @click="sortField = 'end_date'; sortIsAsc = !sortIsAsc;" scope="col" v-if="definitions['End date']">
                            <span class="word">End date</span>
                            <span class="tooltip">
                                {{ definitions['End date'].definition }}
                            </span>
                            <span class="sort-status"><span :class="sortField === 'end_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'end_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable" @click="sortField = 'end_date'; sortIsAsc = !sortIsAsc;" scope="col" v-else>
                            End date <span class="sort-status"><span :class="sortField === 'end_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'end_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                        <th class="clickable" @click="sortField = 'new_end_date'; sortIsAsc = !sortIsAsc;" scope="col">
                            New end date <span class="sort-status"><span :class="sortField === 'new_end_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'new_end_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="contractsGrouped.length > 0">

                    <template v-if="totalContractsCount" v-for="(contractGroup, contractGroupIndex) in contractsGrouped" :key="'contractsGrouped-' + i">
                        <tr>
                            <td v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" data-label="Agency">
                              <template v-if="contractGroup['contracts'].length > 1">
                                <template v-if="contractGroup['contracts'].every( (elem) =>  elem.agency.AgencyRef.name == contractGroup['aggregate'].agency.AgencyRef.name )">
                                  <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                    {{ contractGroup['aggregate'].agency.AgencyRef.name }}
                                  </a>
                                </template>
                                <template v-else>
                                  {{  }}
                                </template>
                              </template>
                              <template v-if="contractGroup['contracts'].length == 1">
                                <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                  {{ contractGroup['aggregate'].agency.AgencyRef.name }}
                                </a>
                              </template>
                            </td>
                            <td data-label="Name">
                                <template v-if="contractGroup['contracts'].length > 1">
                                    <a @click="showContractGroup(contractGroupIndex)" class="button expand-button">
                                        {{ isContractGroupShown(contractGroupIndex) ? '⇧' : '⇩' }} <span>{{ contractGroup['contracts'].length }} contracts for {{ contractGroup['name'] }}</span>
                                    </a>
                            </template>
                            <template v-if="contractGroup['contracts'].length == 1">
                                <template v-if="contractGroup['aggregate'].url">
                                    <a :href="contractGroup['aggregate'].url" v-if="subflyout == false">{{ titleize(contractGroup['aggregate'].name) }}</a>
                                    <button class="contract-button" :aria-label="titleize(contractGroup['aggregate'].name)" @click="emit('call-open-flyout-contract', contractGroup['contracts'][0].locationId)" v-else>{{ titleize(contractGroup['aggregate'].name) }}</button>
                                </template>
                                <template v-else>
                                    <div :class="contractGroup['aggregate'].name == 'Total' ? 'total total-name' : ''">{{ titleize(contractGroup['aggregate'].name) }}</div>
                                </template>
                            </template>
                        </td>
                        <td class="is-family-monospace has-text-right" :class="contractGroup['aggregate'].name == 'Total' ? 'total' : ''" data-label="Total amount">${{ priceFormat(contractGroup['aggregate'].totalAmount) }}</td>
                        <td data-label="Type">{{ contractGroup['aggregate'].type }}</td>

                          <td v-if="vendorshow==true && typeof contractGroup['aggregate'].vendor != 'undefined' && typeof contractGroup['aggregate'].vendor.VendorRef != 'undefined'">
                            <button
                                class="contract-button"
                                :aria-label="contractGroup['aggregate'].vendor.VendorRef.name"
                                @click="emit('call-open-flyout-vendor', contractGroup['aggregate'].vendor.VendorRef.locationId)"
                            >
                              {{ contractGroup['aggregate'].vendor.VendorRef.name }}
                            </button>
                          </td>

                          <td data-label="Begin date">{{ contractGroup['aggregate'].beginDate }}</td>
                            <td data-label="End date">{{ contractGroup['aggregate'].endDate?.includes('9999') ? 'No Current End Date' : contractGroup['aggregate'].endDate }}</td>
                            <td data-label="New end date">{{ contractGroup['aggregate'].newEndDate?.includes('9999') ? 'No Current End Date' : contractGroup['aggregate'].newEndDate }}</td>
                        </tr>
                        <tr
                            v-if="contractGroup['contracts'].length > 1"
                            v-for="(contract, i) in contractGroup['contracts']"
                            :key="contract.contentId"
                            :class="[
                            'extra',
                            isContractGroupShown(contractGroupIndex) ? 'shown' : 'hidden'
                        ]"
                    >
                        <td v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" data-label="Agency">
                            <template v-if="contractGroup['aggregate'].agency">
                                <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                    {{contract.agency.AgencyRef.name}}
                                </a>
                            </template>
                        </td>
                        <td data-label="Name">
                            <template v-if="contract.url">
                                <a :href="contract.url" v-if="subflyout == false" target="_blank">{{ contract.name }}</a>
                                <button class="contract-button" :aria-label="contract.name" @click="emit('call-open-flyout-contract', contract.locationId)" v-else>{{ contract.name }}</button>
                            </template>
                            <template v-else>
                                <div :class="contract.name == 'Total' ? 'total total-name' : ''">{{ contract.name }}</div>
                            </template>
                        </td>
                        <td :class="contract.name == 'Total' ? 'total' : ''" class="is-family-monospace has-text-right" data-label="Total">
                            ${{ priceFormat(contract.totalAmount) }}
                        </td>
                        <td data-label="Type">{{ contract.type }}</td>
                        <td v-if="vendorshow == true">
                            <button
                                class="contract-button"
                                :aria-label="contract.vendor.VendorRef.name"
                                @click="emit('call-open-flyout-vendor', contractGroup['aggregate'].vendor.VendorRef.locationId)"
                            >
                                {{ contract.vendor.VendorRef.name }}
                            </button>
                        </td>
                        <td data-label="Start date">{{ contract.beginDate }}</td>
                        <td data-label="End date">{{ contract.endDate?.includes('9999') ? 'No Current End Date' : contractGroup['aggregate'].endDate }}</td>
                        <td data-label="New end date">{{ contract.newEndDate?.includes('9999') ? 'No Current End Date' : contractGroup['aggregate'].newEndDate }}</td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                    <td colspan="8">
                        <h5 class="search-info"> "No results found"</h5>
                    </td>
                </tbody>
            </table>
            <div class="preloader-container">
                <preloader v-if="loading"/>
            </div>
        </div>
        <pagination v-if=" pageCount > 1"
                    v-on:paginated="paginateContracts($event)"
                    :current="page"
                    :pageCount="pageCount"
        />
    </general-container>
    <!-- End Component: organisms/contracts-list -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import GeneralContainer from "./general-container";
    import {computed, nextTick, onMounted, ref, watch} from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import { priceFormat } from "@/helpers/filter";
    import CustomSelect from "./custom-select";
    import ContractsTableDate from "./contracts-table-date.vue";
    import Datepicker from "vue3-datepicker";
    import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';
    import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
    import Pagination from "./pagination.vue";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import ButtonLink from "./button-link.vue";
    import VendorInformationCard from "./vendor-info-card.vue";
    import { useEventsBus } from "@/helpers/eventBus";
    import { v4 as uuidv4 } from "uuid";

    export default {
        components: {
          VendorInformationCard,
            ButtonLink,
            Pagination,
            Datepicker,
            ContractsTableDate,
            Preloader,
            GeneralContainer,
            CustomSelect,
            FontAwesomeIcon
        },
        props: [
            'searchAgency',
            'agencyIdOnly',
            'searchDomain',
            'searchType',
            'vendorId',
            'programId',
            'title',
            'titleClass',
            'contractsIds',
            'customContainerClass',
            'scrollable',
            'hideAgencyName',
            'subflyout',
            'minAmount',
            'maxAmount',
            'vendorshow',
            'vendorInfo',
        ],
        data() {
            return {
                contractGroupShowing: {},
                random: Math.random(),
                faSearch: faMagnifyingGlass,
                faCalendarDay: faCalendarDay,
            };
        },
        methods: {
            isContractGroupShown(contractGroupIndex) {
                if (!this.contractGroupShowing.hasOwnProperty(contractGroupIndex)) {
                    return false;
                }
                return this.contractGroupShowing[contractGroupIndex];
            },
            showContractGroup(contractGroupIndex) {
                if (!this.contractGroupShowing.hasOwnProperty(contractGroupIndex)) {
                    this.contractGroupShowing[contractGroupIndex] = true;
                    this.random = Math.random();
                    this.$forceUpdate();
                    return;
                }
                this.contractGroupShowing[contractGroupIndex] = !this.contractGroupShowing[contractGroupIndex];
                this.random = Math.random();
                this.$forceUpdate();
            },
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
        },
        computed: {
            contractsGrouped: function() {
                let v = [];
                let seenNames = [];
                for (let i = 0; i < this.contracts.length; i++) {
                    let name = this.contracts[i].name;
                    let agencyName = this.contracts[i].agency.AgencyRef.name
                    if (seenNames.indexOf(name) >= 0) {
                        continue;
                    }
                    let contracts = [];
                    let contractType = this.contracts[i].type
                    contracts.push(this.contracts[i]);
                    for (let j = i + 1; j < this.contracts.length; j++) {
                        let jName = this.contracts[j].name;
                        let jAgencyName = this.contracts[j].agency.AgencyRef.name
                        if (name !== jName || agencyName !== jAgencyName) {
                            continue;
                        }
                        contracts.push(this.contracts[j]);
                        contractType =contractType !== this.contracts[j].type ? '' : this.contracts[j].type;
                    }
                    seenNames.push(name);

                    let aggregate = {
                        'name': contracts[0].name,
                        'url': contracts[0].url,
                        'type': contractType,
                        'totalAmount': 0,
                        'beginDate': null,
                        'endDate': null,
                        'newEndDate': null,
                        'agency': contracts[0].agency,
                        'vendor':contracts[0].vendor,
                    };
                    let dateKeys = ['beginDate', 'endDate', 'newEndDate',];
                    for (let j = 0; j < contracts.length; j++) {
                        aggregate['totalAmount'] += contracts[j].totalAmount;
                        for (let k = 0; k < dateKeys.length; k++) {
                            if (!contracts[j][dateKeys[k]]) {
                                continue;
                            }
                            let thisDate = new Date(contracts[j][dateKeys[k]]);
                            if (aggregate[dateKeys[k]] === null) {
                                aggregate[dateKeys[k]] = contracts[j][dateKeys[k]];
                                continue;
                            }
                            let aggregateDate = new Date(aggregate[dateKeys[k]]);
                            if (dateKeys[k] === 'beginDate') {
                                if (thisDate < aggregateDate) {
                                    aggregate[dateKeys[k]] = contracts[j][dateKeys[k]];
                                }
                            }
                            else {
                                if (thisDate > aggregateDate) {
                                    aggregate[dateKeys[k]] = contracts[j][dateKeys[k]];
                                }
                            }
                        }
                    }

                    let item = {
                        'name': name,
                        'aggregate': aggregate,
                        'contracts': contracts,
                    };
                    v.push(item);
                }
                return v;
            },
        },
        setup(props) {
            const hasFilters = ref(false);
            const itemsPerPage = ref(10);
            const loading = ref(true);
            const contracts = ref([]);
            const page = ref(1);
            const keyword = ref('');
            const totalContractsCount = ref(null);
            const pageCount = ref(1);
            const sortField = ref('total_amount');
            const sortIsAsc = ref(false);
            const selectedContract = ref(null);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const contractContentId = ref(null);
            const contractProps = ref(null);
            const appliedFilters = ref([]);
            let currentYear = new Date().getFullYear();
            const year = ref('');
            const type = ref('');
            const typesValues = ref();
            const {emit}=useEventsBus();
            const types = ref([
                {
                    value: 'type',
                    name: 'All Types',
                },
                {
                    value: 'Contract',
                    name: 'Contract',
                },
                {
                    value: 'PO',
                    name: 'Purchase Order',
                },
                {
                    value: 'Grant',
                    name: 'Grant',
                },
            ]);
            const domain = ref('');
            const domainsValues = ref();
            const domains = ref([
                {
                    value: 'domain',
                    name: 'All Domains',
                },
                {
                    value: 'Health and Human Services',
                    name: 'Health and Human Services',
                },
                {
                    value: 'Justice and Public Safety',
                    name: 'Justice and Public Safety',
                },
                {
                    value: 'Environment, Agriculture, and Natural Resources',
                    name: 'Environment, Agriculture, and Natural Resources',
                },
                {
                    value: 'Infrastructure, Tourism, and Economic Development',
                    name: 'Infrastructure, Tourism, and Economic Development',
                },
                {
                    value: 'Education',
                    name: 'Education',
                },
                {
                    value: 'General Government, State Administration, and Technology',
                    name: 'General Government, State Administration, and Technology',
                },
                {
                    value: 'Legislature - House and Senate',
                    name: 'Legislature - House and Senate',
                },
            ]);
            const agencies = computed(() => store.getters.agencies || []);
            const agencyId = ref('');
            const agencyIds = ref([]);
            let ID = ref('');
            const minAmount =ref(0);
            const maxAmount =ref(0);
            let startDate = ref();
            let endDate = ref();
            const exactMatch = ref(0);
            const loadContracts = (reset) => {
                loading.value = true;
                if (reset) {
                    contracts.value = [];
                    page.value = 1;
                    pageCount.value = null
                    totalContractsCount.value = null;
                }
                let dStart = '';
                if (startDate.value) {
                    dStart = startDate.value.toISOString().split('T')[0];
                }
                let dEnd = '';
                if (endDate.value) {
                    dEnd = endDate.value.toISOString().split('T')[0];
                }
                let originalSearchQuery = keyword.value;
                let searchQuery = keyword.value;
                if (exactMatch.value) {
                    searchQuery = '"' + searchQuery + '"';
                }
                RequestHandler.loadContracts(
                    itemsPerPage.value,
                    (page.value - 1) * itemsPerPage.value,
                    props.vendorId,
                    props.programId,
                    ID.value,
                    props.contractsIds,
                    searchQuery,
                    sortField.value,
                    sortIsAsc.value,
                    minAmount.value,
                    maxAmount.value,
                    dStart,
                    dEnd,
                    year.value,
                    type.value,
                    exactMatch.value,
                )
                    .then(data => {
                        if (originalSearchQuery !== keyword.value) {
                            return;
                        }
                        loading.value = false;
                        totalContractsCount.value = data.totalCount || 0;
                        contracts.value = data.list ;
                        pageCount.value = data.pageCount ;
                    })
                    .catch(error => {
                        console.error(error);
                        loading.value = false;
                    });
            };

            const paginateContracts = (pageTo) => {
                page.value = pageTo;
                loadContracts();
            };

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            const searchCurrentYear = () => {
                let today = new Date()
                let startFiscal = ''
                let endFiscal = ''
                if ((today.getMonth()+1)<=6)
                {
                    startFiscal =  today.getFullYear()-1
                    endFiscal  = today.getFullYear()
                }
                else {
                    startFiscal =  today.getFullYear()
                    endFiscal  = today.getFullYear()+1
                }
                startDate.value = (new Date(startFiscal,6,1) )
                 endDate.value = (new Date(endFiscal,5,30))
                year.value = ''
                loadContracts(true)
            }

            const allYear = () => {
                year.value = 'all'
                startDate.value = ''
                endDate.value = ''
                loadContracts(true)
            }

            let timer = null;
            watch(keyword, (newVal) => {
                hasFilters.value = true;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(() => loadContracts(true), 600);
            });
            watch(sortIsAsc, () => loadContracts(true));
            watch(minAmount, (newVal) => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(() => loadContracts(true), 600);
            });
            watch(maxAmount, (newVal) => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(() => loadContracts(true), 600);
            });
            watch(startDate, () => {
                if (startDate) {
                    loadContracts(true)
                }
            });
            watch(endDate, () => {
                if (endDate) {
                    loadContracts(true)
                }
            });
            watch(props, () => loadContracts(true));
            watch (itemsPerPage, () => loadContracts(true));
            watch(agencyId, () => {
                ID.value = agencyId.value
                if (agencyId.value == '') { loadContracts(true)} else  loadContracts()
            })
            watch(type, ()=> {
                if (type.value == 'type') { loadContracts(true)} else  loadContracts()
            })
            onMounted(() => {
                if (props.searchAgency == false) {ID.value = props.agencyIdOnly} else {ID.value = agencyId.value}
                props.vendorInfo ? ( sortField.value = 'begin_date', allYear()) : loadContracts()
            });

            const clickExactMatchContracts = (event) => {
                exactMatch.value = event.target.checked ? 1 : 0;

                nextTick(() => {
                    loadContracts(true);
                });
            }
            return {
                emit,
                hasFilters,
                loading,
                contracts,
                page,
                totalContractsCount,
                pageCount,
                keyword,
                priceFormat,
                sortField,
                sortIsAsc,
                selectedContract,
                contractContentId,
                contractProps,
                definitions,
                year,
                agencies,
                agencyId,
                agencyIds,
                minAmount,
                maxAmount,
                types,
                typesValues,
                type,
                appliedFilters,
                loadContracts,
                domain,
                domainsValues,
                domains,
                paginateContracts,
                itemsPerPage,
                ID,
                searchCurrentYear,
                allYear,
                startDate,
                endDate,
                clickExactMatchContracts,
                exactMatch,
                uniqueId,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/helpers/responsive-tables";

    .inactive-sort {
        opacity: 0.45;
    }

    .active-sort {
        opacity: 1.0;
    }

    .filter-container .control {
        display: flex;
    }

    .filter-container .control .dropdown {
        flex: 1;
    }

    .columns {
        margin-top: 0rem;
    }

    .column {
        padding: 0em 0.75rem;
    }

    .range_filter_preset {
        padding: 15px;
        cursor: pointer;
        text-decoration: underline;
    }

    .input-amount {
        height: 55px;
        border: 2px solid #022c43;
        border-radius: 6px;
        padding: 10px;
    }
    table {
        margin: auto;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
        }

        tfoot td {
            background-color: $grey--light;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }
    }
    @include block('search-block') {
        position: relative;

        @include element('container') {
            display: flex;
            justify-content: flex-start;
        }

        .control:first-of-type {
            @media screen and (min-width: 1216px) {
                width: calc(100% - 60px) !important;
            }
            @media screen and (max-width: 960px) {
                width: calc(100% - 60px) !important;
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 40px) !important;
            }
        }

        .control:last-of-type {
            @media screen and (min-width: 1216px) {
                width: 60px !important;
            }
            @media screen and (max-width: 960px) {
                width: 60px !important;
            }
            @media screen and (max-width: 768px) {
                width: 40px !important;
            }
        }

        @include element('search-input') {
            border: 2px solid $blue--dark;
            border-right: none;
            @include element('icon') {
                svg {
                    width: 24px !important;
                    height: 36px !important;
                }
            }
        }

        .button {
            height: 55px;
            width: 100%;
            background-color: $white;
            color: $grey--lighter;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border: 2px solid $blue--dark;
            border-left: none;
            padding-right: 0.5em;
            cursor: default !important;
        }

        input[type="search"]::-webkit-search-decoration:hover,
        input[type="search"]::-webkit-search-cancel-button:hover {
            cursor:pointer;
        }
    }

    .zebra tr:nth-child(odd) {
        background-color: $white;
    }
    .amount {height: 100%}
    .date-range-card {
        .control {
            margin-bottom: 10px;
            display: flex;
            .control-icon {
                float: left;
                padding-right: 10px;
                color: $blue--dark;
                font-size: 28px;
            }
            .control-input {
                float: left;
                display: inline-block;
                position: relative;
            }
        }
        .control-title {
            display: block;
            width: 100%;
        }
    }
    .tbl-scrollable {
        max-height: 75vh;
        overflow-y: auto;
        @media screen and (min-width: 601px) {
            background-color: $white;
        }
        table {
            width: 100%;
        }
        thead th {
            position: sticky;
            top: 0;
        }
    }
    #tbl-keyword {
       height: 55px;
    }
    .fitler-container {
        display: flex!important;
        justify-content: space-evenly;
        flex-direction: column;
    }

    b:hover {
     text-decoration-line: underline;
    }

    .filter-info {
        text-align: center;
        font-size: 21px;
        font-weight: 600;
    }
    .filter-limit {
        text-align: right;
        margin-bottom: .75rem;
    }

    .fitler-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 10px 0;
    }
    .extra.hidden {
        display: none;
    }
    .extra.shown {
    }
    .extra td:first-child a {
        margin-left: 10px;
    }

    .total-name {
        text-align: right;
    }
    .total {
        font-weight: bold;
    }
    .search-info {
        text-align: center;
    }
.clickable {
    cursor: pointer;
}
.clickable span.tooltip {
    display: none;
    position: relative;
    border: 5px solid $blue--dark;
    &:after {
        bottom: 100%;
        left: 20%;
        border: solid transparent;
        content: " ";
        position: absolute;
    }
    &:after {
        border-bottom-color: $blue--dark;
        border-width: 15px;
    }
}
.clickable:hover span.tooltip {
    position: absolute;
    top: 45px;
    left: 0%;
    display: inline;
    background-color: $blue--dark;
    color: $white;
    padding: 5px;
    font-weight: 400;
    border-radius: 4px;
}

.contract-button {
    color: $blue--med;
    transition: all 0.3s ease-in-out;
    border: none;
    background: none;
    font-size: 16px;
    text-decoration: underline;
    font-family: Montserrat, sans-serif;
    line-height: 1.5em;
    text-align: left;
    cursor: pointer;

    &:hover, &:focus, &:active {
        color: $blue--dark;
    }
}
.h-58 {
    height: 58px !important;
}

.clickable.has-tooltip-hover:hover {
    position: sticky;
}
</style>
