import PageBudgetDashboard from './components/budget-dashboard';
import PageContractsDashboard from './components/contracts-dashboard';
import PageGuidance from './components/guidance';
import PageHomepage from './components/homepage';
import PageInfoBlockTests from './components/info-block-tests';
import PageRecentUpdates from './components/recent-updates';
import PageRelevantUpdates from './components/relevant-updates';
import PageSearchResults from './components/search-results';
import Empty from "./components/empty";
export default [
{path: "/verify", component: () => import('./components/verification-form'), name: "verify", meta:{ title:"Verify email" }},
{path: "/agency-contacts", component: () => import('./components/agency-contacts'), name: "agency-contacts", meta:{ title:"Agency Contacts" }},
{path: "/browse-agencies", component: () => import('./components/browse-agencies'), name: "browse-agencies", meta:{ title:"Browse Agencies" }},
{path: "/browse-budgets", component: () => import('./components/browse-budgets'), name: "browse-budgets", meta:{ title:"Browse Budgets" }},
{path: "/browse-contracts", component: () => import('./components/browse-contracts'), name: "browse-contracts", meta:{ title:"Browse Contracts" }},
{path: "/browse-documents", component: () => import('./components/browse-documents'), name: "browse-documents", meta:{ title:"Browse Documents" }},
{path: "/browse-procurements", component: () => import('./components/browse-procurements'), name: "browse-procurements", meta:{ title:"Browse Bids" }},
{path: "/budget-dashboard", component: PageBudgetDashboard, name: "budget-dashboard", meta:{ title:"budget-dashboard" }},
{path: "/dashboard", component: () => import('./components/calendar-bulma'), name: "dashboard", meta:{ title:"Dashboard" }},
{path: "/dashboard", component: () => import('./components/calendar-tockify'), name: "dashboard", meta:{ title:"Dashboard" }},
{path: "/contact-travels/:contactUrl(.*)", component: () => import('./components/contact-travels'), name: "contact-travels", meta:{ title:"Travels" }},
{path: "/contracts-dashboard", component: PageContractsDashboard, name: "contracts-dashboard", meta:{ title:"contracts-dashboard" }},
{path: "/dashboard", component: () => import('./components/dashboard'), name: "dashboard", meta:{ title:"Dashboard" }},
{path: "/gov-guidance", component: PageGuidance, name: "govguidance", meta:{ title:"GovGuidance" }},
{path: "/", component: PageHomepage, name: "homepage", meta:{ title:"Home" }},
{path: "/", component: PageInfoBlockTests, name: "info block test", meta:{ title:"Home - MyGovGuide" }},
{path: "/login", component: () => import('./components/login'), name: "login", meta:{ title:"Sign In" }},
{path: "/my-bookmarks", component: () => import('./components/my-bookmarks'), name: "my-bookmarks", meta:{ title:"My Bookmarks" }},
{path: "/my-notes", component: () => import('./components/my-notes'), name: "my-notes", meta:{ title:"My Notes" }},
{path: "/my-notifications", component: () => import('./components/my-notifications'), name: "my-notifications", meta:{ title:"My Notifications" }},
{path: "/my-notification-settings", component: () => import('./components/my-notification-settings'), name: "Notification Settings", meta:{ title:"Notification Settings" }},
{path: "/profile", component: () => import('./components/my-profile'), name: "profile", meta:{ title:"My Profile" }},
{path: "/organizational-chart", component: () => import('./components/org-chart'), name: "organizational-chart", meta:{ title:"Organizational Chart" }},
{path: "/previous-contacts", component: () => import('./components/previous-contacts'), name: "previous-contacts", meta:{ title:"Previous Contacts" }},
{path: "/primer", component: () => import('./components/primer'), name: "primer", meta:{ title:"Primer" }},
{path: "/program-contacts", component: () => import('./components/program-contacts'), name: "program-contacts", meta:{ title:"Program Contacts" }},
{path: "/recent-news", component: () => import('./components/recent-news'), name: "recent-news", meta:{ title:"Recent News" }},
{path: "/recent-updates", component: PageRecentUpdates, name: "recent-updates", meta:{ title:"Recent Updates" }},
{path: "/register", component: () => import('./components/register'), name: "register", meta:{ title:"Register New User" }},
{path: "/relevant-updates", component: PageRelevantUpdates, name: "relevant-updates", meta:{ title:"Relevant Updates" }},
{path: "/user/reset-password/:token", component: () => import('./components/reset-password'), name: "reset-password", meta:{ title:"Reset Password" }},
{path: "/search", component: PageSearchResults, name: "search", meta:{ title:"Search" }},
{path: "/teaser", component: () => import('./components/teaser'), name: "teaser", meta:{ title:"My Teaser" }},
{path: "/timeline-test", component: () => import('./components/timeline-test-page'), name: "timeline-test", meta:{ title:"Timeline Test" }},
{path: '/:pathMatch(.*)', component: Empty, name: 'PageEmpty'}
];